<template>
  <div class="indoor-control-parameters">
    <PageTitle
      :title="BranchName"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'IndoorControlSettings' })"
    />
    <div v-if="tableData.length">
      <DoorControl :data="doorList" :loading="loading" @updateDevice="updateDevice" @resetPassword="resetPassword" />
      <DeviceSwitchBlock :data.sync="deviceList" :loading="loading" @updateDevice="updateDevice" />
    </div>
    <div v-else class="empty-block">
      <img
        width="150"
        style="margin: auto"
        src="@/assets/icon/nodata/no_data.svg"
        alt=""
        srcset=""
      >
      <span class="content">目前尚未有完成串接的設備，<br>
        請您先前往「基本參數設定 > 門市設定」<br>
        新增門市，並聯繫客服。</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
// import CategoryBlock from '../components/CategoryBlock.vue'
import { FindDevice, UpdateDevice, ResetPassword } from '@/api/indoorControl'
import store from '@/store'
import { map, get, filter } from 'lodash'
import { useRoute } from 'vue-router/composables'
import DeviceSwitchBlock from './components/DeviceSwitchBlock.vue'
import DoorControl from './components/DoorControl.vue'

export default defineComponent({
  name: 'IndoorControlDetailSettings',
  components: { PageTitle, DeviceSwitchBlock, DoorControl },
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const tableData = ref([])
    const route = useRoute()
    const BranchId = computed(() => route.params.id)
    const BranchName = computed(() => route.params.branchName)

    const getStore = async () => {
      loading.value = true
      const [res, err] = await FindDevice({
        shopId: shopId.value,
        BranchId: BranchId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      tableData.value = res
      loading.value = false
    }

    const doorList = computed(() => {
      return map(filter(tableData.value, item => get(item, 'IndoorControlDevice.type') === 'door'), item => {
        return {
          id: get(item, 'IndoorControlDevice.id'),
          name: get(item, 'IndoorControlDevice.name'),
          password: get(item, 'password'),
        }
      })
    })
    const deviceList = computed(() => {
      return map(filter(tableData.value, item => get(item, 'IndoorControlDevice.type') === 'switch'), item => {
        return {
          id: get(item, 'IndoorControlDevice.id'),
          name: get(item, 'IndoorControlDevice.name'),
          status: get(item, 'status') === 'on',
        }
      })
    })
    const updateDevice = async (id, status) => {
      const [, err] = await UpdateDevice({
        shopId: shopId.value,
        id,
        status: status ? 'on' : 'off',
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      await getStore()
    }
    const resetPassword = async (id) => {
      const [, err] = await ResetPassword({
        shopId: shopId.value,
        id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功')
      await getStore()
    }
    onMounted(async () => {
      await getStore()
    })
    return {
      tableData,
      doorList,
      deviceList,
      updateDevice,
      resetPassword,
      loading,
      BranchName,
    }
  },
})
</script>

<style scoped lang="postcss">

.empty-block {
  @apply flex flex-col w-full justify-center items-center leading-[30px] p-[20px] text-[14px] mt-[160px];
}

.content {
  @apply text-center text-sub leading-normal tracking-[1px] text-gray-100 mt-[8px];
}
</style>
