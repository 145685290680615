<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="硬體設備開關" hideBtn />
    <el-form label-position="left" label-width="200px">
      <el-form-item v-for="item in formData" :key="item.id" :label="item.name">
        <el-switch
          v-model="item.status"
          class="switch-input"
          active-text="是"
          inactive-text="否"
          @change="handleChange(item)"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { ref, defineComponent, watch, onMounted } from 'vue'
export default defineComponent({
  name: 'DeviceSwitchBlock',
  components: {
    SectionTitle,
  },
  props: {
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const formData = ref([])
    const syncData = async () => {
      formData.value = props.data
    }
    const handleChange = async (item) => {
      console.log(item)
      emit('updateDevice', item.id, item.status)
    }

    watch(() => props.data, async () => {
      await syncData()
    })

    onMounted(async () => {
      await syncData()
    })

    return { formData, handleChange }
  },
})
</script>
